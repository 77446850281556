/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DisturbanceResult } from "../models/DisturbanceResult";
import type { DisturbanceServiceResult } from "../models/DisturbanceServiceResult";
import type { RelatedCompaniesResult } from "../models/RelatedCompaniesResult";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class DisturbanceControllerV2Service {
  /**
   * Get disturbance that reported to the organization for the loggedin customer
   * @param xAuthScopeId
   * @param organizationNumber
   * @param acceptLanguage
   * @returns DisturbanceResult OK
   * @throws ApiError
   */
  public static getDisturbance1(
    xAuthScopeId: string,
    organizationNumber: string,
    acceptLanguage?: string
  ): CancelablePromise<DisturbanceResult> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/v2/disturbance",
      headers: {
        "X-Auth-Scope-Id": xAuthScopeId,
        "Accept-Language": acceptLanguage,
      },
      query: {
        organizationNumber: organizationNumber,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get disturbance that reported to the organization for the loggedin customer
   * @param xAuthScopeId
   * @param organizationNumber
   * @param acceptLanguage
   * @returns DisturbanceServiceResult OK
   * @throws ApiError
   */
  public static getDisturbanceServices1(
    xAuthScopeId: string,
    organizationNumber: string,
    acceptLanguage?: string
  ): CancelablePromise<DisturbanceServiceResult> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/v2/disturbance/services",
      headers: {
        "X-Auth-Scope-Id": xAuthScopeId,
        "Accept-Language": acceptLanguage,
      },
      query: {
        organizationNumber: organizationNumber,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Related companies that reported to the organization for the loggedin customer.
   * @param xAuthScopeId
   * @param organizationNumber
   * @param acceptLanguage
   * @returns RelatedCompaniesResult OK
   * @throws ApiError
   */
  public static getRelatedCompanies1(
    xAuthScopeId: any,
    organizationNumber: string,
    acceptLanguage?: string
  ): CancelablePromise<RelatedCompaniesResult> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/v2/disturbance/relatedcompanies",
      headers: {
        "X-Auth-Scope-Id": xAuthScopeId,
        "Accept-Language": acceptLanguage,
      },
      query: {
        organizationNumber: organizationNumber,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        500: `Internal Server Error`,
      },
    });
  }
}
