/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { OrderCaseCreateRequestReporter } from './OrderCaseCreateRequestReporter';

export type OrderCaseCreateRequest = {
  orderUid?: string;
  tscId?: string;
  category: OrderCaseCreateRequest.category;
  subCategory?: OrderCaseCreateRequest.subCategory;
  shortDescription: string;
  description?: string;
  reporter: OrderCaseCreateRequestReporter;
  customerReferenceId?: string;
  watchList?: Array<string>;
  c2bUid?: string;
};

export namespace OrderCaseCreateRequest {

    export enum category {
        DELIVERY = 'DELIVERY',
        ORDER = 'ORDER',
    }

    export enum subCategory {
        CHANGE_ORDER = 'CHANGE_ORDER',
        CHANGE_DELIVERY_DATE = 'CHANGE_DELIVERY_DATE',
        CANCEL_ORDER = 'CANCEL_ORDER',
        OTHER_QUESTION = 'OTHER_QUESTION',
        DELIVERY_COMPLAINT = 'DELIVERY_COMPLAINT',
        CALL_ORDER = 'CALL_ORDER',
        CHANGE_OR_MOVE = 'CHANGE_OR_MOVE',
        IMPORTING = 'IMPORTING',
        CANCELLATION = 'CANCELLATION',
        TRANSFER = 'TRANSFER',
    }


}
