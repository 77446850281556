/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { RelatedCase } from './RelatedCase';

export type SubscriptionAvailability = {
    subscriptionNumber?: string;
    c2bCacheUid?: string;
    c2bUid?: string;
    actualAvailability?: string;
    commitmentAvailability?: string;
    predictiveAvailability?: string;
    alias?: string;
    service?: string;
    city?: string;
    street?: string;
    country?: string;
    cases?: Array<RelatedCase>;
    relatedCases?: Array<string>;
    vpnList?: Array<string>;
    organizationName?: string;
    status?: SubscriptionAvailability.status;
};

export namespace SubscriptionAvailability {

    export enum status {
        FINAL = 'FINAL',
        PRELIMINARY = 'PRELIMINARY',
    }


}
