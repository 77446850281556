/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AttachmentInfo } from '../models/AttachmentInfo';
import type { StreamingResponseBody } from '../models/StreamingResponseBody';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AttachmentService {

    /**
     * Add  attachment to a Case
     * @param caseId
     * @param xAuthScopeId
     * @param formData
     * @returns AttachmentInfo OK
     * @throws ApiError
     */
    public static addAttachmentToCase(
        caseId: string,
        xAuthScopeId: any,
        formData: {
            file: Blob;
        },
    ): CancelablePromise<AttachmentInfo> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/cases/{caseId}/attachments',
            path: {
                'caseId': caseId,
            },
            headers: {
                'X-Auth-Scope-Id': xAuthScopeId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                405: `Method Not Allowed`,
                410: `Gone`,
                415: `Unsupported Media Type`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Returns details for attachment
     * @param caseId
     * @param attachmentId
     * @param xAuthScopeId
     * @returns StreamingResponseBody OK
     * @throws ApiError
     */
    public static getAttachmentOnCase(
        caseId: string,
        attachmentId: string,
        xAuthScopeId: any,
    ): CancelablePromise<StreamingResponseBody> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/cases/{caseId}/attachments/{attachmentId}',
            path: {
                'caseId': caseId,
                'attachmentId': attachmentId,
            },
            headers: {
                'X-Auth-Scope-Id': xAuthScopeId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                405: `Method Not Allowed`,
                410: `Gone`,
                415: `Unsupported Media Type`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Delete attachment
     * @param caseId
     * @param attachmentId
     * @param xAuthScopeId
     * @returns any OK
     * @throws ApiError
     */
    public static deleteAttachmentOnCase(
        caseId: string,
        attachmentId: string,
        xAuthScopeId: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/cases/{caseId}/attachments/{attachmentId}',
            path: {
                'caseId': caseId,
                'attachmentId': attachmentId,
            },
            headers: {
                'X-Auth-Scope-Id': xAuthScopeId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                405: `Method Not Allowed`,
                410: `Gone`,
                415: `Unsupported Media Type`,
                500: `Internal Server Error`,
            },
        });
    }

}