/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ServiceObject } from '../models/ServiceObject';
import type { SubscriptionAvailabilityResponse } from '../models/SubscriptionAvailabilityResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SlaService {

    /**
     * Returns details for service object instances
     * @param c2BUid
     * @param xAuthScopeId
     * @returns ServiceObject OK
     * @throws ApiError
     */
    public static getServiceObject(
        c2BUid: string,
        xAuthScopeId: any,
    ): CancelablePromise<ServiceObject> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/service-object-instances',
            headers: {
                'X-Auth-Scope-Id': xAuthScopeId,
            },
            query: {
                'c2bUid': c2BUid,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                405: `Method Not Allowed`,
                410: `Gone`,
                415: `Unsupported Media Type`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Returns a list of subscription along with 3 months average availability with less than 100% guaranteed connected to the org
     * @param endDate Date formats: [dd/MM/yyyy][yyyy/MM/dd][dd-MM-yyyy][yyyy-MM-dd]
     * @param xAuthScopeId
     * @param organizationNumbers Please use tscId instead
     * @param tscId
     * @param startDate Date formats: [dd/MM/yyyy][yyyy/MM/dd][dd-MM-yyyy][yyyy-MM-dd]
     * @param periodLength Maximum possible value is 24. Deprecated, please use startDate instead
     * @returns SubscriptionAvailabilityResponse OK
     * @throws ApiError
     */
    public static getSubscriptionAvailability(
        endDate: string,
        xAuthScopeId: any,
        organizationNumbers?: Array<string>,
        tscId?: string,
        startDate?: string,
        periodLength?: number,
    ): CancelablePromise<SubscriptionAvailabilityResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/reports/subscription-availability',
            headers: {
                'X-Auth-Scope-Id': xAuthScopeId,
            },
            query: {
                'organizationNumbers': organizationNumbers,
                'tscId': tscId,
                'startDate': startDate,
                'endDate': endDate,
                'periodLength': periodLength,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                405: `Method Not Allowed`,
                410: `Gone`,
                415: `Unsupported Media Type`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Export subscription availabilities
     * @param endDate Date formats: [dd/MM/yyyy][yyyy/MM/dd][dd-MM-yyyy][yyyy-MM-dd]
     * @param xAuthScopeId
     * @param organizationNumbers Please use tscId instead
     * @param tscId
     * @param startDate Date formats: [dd/MM/yyyy][yyyy/MM/dd][dd-MM-yyyy][yyyy-MM-dd]
     * @param periodLength Maximum possible value is 24. Deprecated, please use startDate instead
     * @returns string OK
     * @throws ApiError
     */
    public static exportSubscriptionAvailability(
        endDate: string,
        xAuthScopeId: any,
        organizationNumbers?: Array<string>,
        tscId?: string,
        startDate?: string,
        periodLength?: number,
    ): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/reports/subscription-availability/export',
            headers: {
                'X-Auth-Scope-Id': xAuthScopeId,
            },
            query: {
                'organizationNumbers': organizationNumbers,
                'tscId': tscId,
                'startDate': startDate,
                'endDate': endDate,
                'periodLength': periodLength,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                405: `Method Not Allowed`,
                410: `Gone`,
                415: `Unsupported Media Type`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Export network availabilities
     * @param reportDate Date formats: [dd/MM/yyyy][yyyy/MM/dd][dd-MM-yyyy][yyyy-MM-dd]
     * @param xAuthScopeId
     * @param organizationNumbers Please use tscId instead
     * @param serviceType Parameter used for filtering only in network availability export
     * @param tscId
     * @returns string OK
     * @throws ApiError
     */
    public static exportNetworkAvailability(
        reportDate: string,
        xAuthScopeId: any,
        organizationNumbers?: Array<string>,
        serviceType?: string,
        tscId?: string,
    ): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/reports/network-availability/export',
            headers: {
                'X-Auth-Scope-Id': xAuthScopeId,
            },
            query: {
                'organizationNumbers': organizationNumbers,
                'serviceType': serviceType,
                'tscId': tscId,
                'reportDate': reportDate,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                405: `Method Not Allowed`,
                410: `Gone`,
                415: `Unsupported Media Type`,
                500: `Internal Server Error`,
            },
        });
    }

}