/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateReporterRequest } from '../models/CreateReporterRequest';
import type { CreateReporterResponse } from '../models/CreateReporterResponse';
import type { GetContactResponse } from '../models/GetContactResponse';
import type { UpdateReporterRequest } from '../models/UpdateReporterRequest';
import type { UpdateReporterResponse } from '../models/UpdateReporterResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CaseReporterControllerService {

    /**
     * Updates reporter
     * @param sysId
     * @param xAuthScopeId
     * @param requestBody
     * @returns UpdateReporterResponse OK
     * @throws ApiError
     */
    public static updateReporter(
        sysId: string,
        xAuthScopeId: any,
        requestBody: UpdateReporterRequest,
    ): CancelablePromise<UpdateReporterResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/reporters/{sysId}',
            path: {
                'sysId': sysId,
            },
            headers: {
                'X-Auth-Scope-Id': xAuthScopeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Fetches a reporter for logged in user in selected organization
     * @param xAuthScopeId
     * @param tscId
     * @param uorUid
     * @returns GetContactResponse OK
     * @throws ApiError
     */
    public static getReporter(
        xAuthScopeId: any,
        tscId?: string,
        uorUid?: string,
    ): CancelablePromise<GetContactResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/reporters',
            headers: {
                'X-Auth-Scope-Id': xAuthScopeId,
            },
            query: {
                'tscId': tscId,
                'uorUid': uorUid,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Creates new reporter
     * @param xAuthScopeId
     * @param requestBody
     * @returns CreateReporterResponse OK
     * @throws ApiError
     */
    public static createReporter(
        xAuthScopeId: any,
        requestBody: CreateReporterRequest,
    ): CancelablePromise<CreateReporterResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/reporters',
            headers: {
                'X-Auth-Scope-Id': xAuthScopeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                500: `Internal Server Error`,
            },
        });
    }

}