/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ContactPerson } from './ContactPerson';

export type OrderCase = {
    caseId?: string;
    shortDescription?: string;
    status?: OrderCase.status;
    createdOn?: string;
    resolvedOn?: string;
    updatedOn?: string;
    contactPerson?: ContactPerson;
    category?: string;
    subCategory?: string;
};

export namespace OrderCase {

    export enum status {
        AWAITING_INFO = 'AWAITING_INFO',
        CLOSED = 'CLOSED',
        ONGOING = 'ONGOING',
        RESOLVED = 'RESOLVED',
    }


}
