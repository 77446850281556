/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OrderCaseCreateRequest } from '../models/OrderCaseCreateRequest';
import type { OrderCaseCreateResponse } from '../models/OrderCaseCreateResponse';
import type { OrderCaseListGetResponse } from '../models/OrderCaseListGetResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OrderCaseControllerService {

    /**
     * Read order case list
     * @param uorUid
     * @param xAuthScopeId
     * @param acceptLanguage
     * @returns OrderCaseListGetResponse OK
     * @throws ApiError
     */
    public static getOrderCaseList(
        uorUid: string,
        xAuthScopeId: any,
        acceptLanguage?: any,
    ): CancelablePromise<OrderCaseListGetResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/order-cases',
            headers: {
                'Accept-Language': acceptLanguage,
                'X-Auth-Scope-Id': xAuthScopeId,
            },
            query: {
                'uorUid': uorUid,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                405: `Method Not Allowed`,
                410: `Gone`,
                415: `Unsupported Media Type`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Creates new order case
     * @param xAuthScopeId
     * @param requestBody
     * @returns OrderCaseCreateResponse Created
     * @throws ApiError
     */
    public static createOrderCase(
        xAuthScopeId: any,
        requestBody: OrderCaseCreateRequest,
    ): CancelablePromise<OrderCaseCreateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/order-cases',
            headers: {
                'X-Auth-Scope-Id': xAuthScopeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                405: `Method Not Allowed`,
                410: `Gone`,
                415: `Unsupported Media Type`,
                500: `Internal Server Error`,
            },
        });
    }

}