/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NetworkAvailabilityResponse } from '../models/NetworkAvailabilityResponse';
import type { SummarisedNetworkAvailabilitiesResponse } from '../models/SummarisedNetworkAvailabilitiesResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ReportService {

    /**
     * Returns a list of subscription along with 3 months average availability with less than 100% guaranteed connected to the org
     * @param reportDate Date formats: [dd/MM/yyyy][yyyy/MM/dd][dd-MM-yyyy][yyyy-MM-dd]
     * @param xAuthScopeId
     * @param organizationNumbers Please use tscId instead
     * @param serviceType Parameter used for filtering only in network availability export
     * @param tscId
     * @returns SummarisedNetworkAvailabilitiesResponse OK
     * @throws ApiError
     */
    public static getNetworkAvailability(
        reportDate: string,
        xAuthScopeId: any,
        organizationNumbers?: Array<string>,
        serviceType?: string,
        tscId?: string,
    ): CancelablePromise<SummarisedNetworkAvailabilitiesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/reports/network-availability',
            headers: {
                'X-Auth-Scope-Id': xAuthScopeId,
            },
            query: {
                'organizationNumbers': organizationNumbers,
                'serviceType': serviceType,
                'tscId': tscId,
                'reportDate': reportDate,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                405: `Method Not Allowed`,
                410: `Gone`,
                415: `Unsupported Media Type`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Returns a list of subscription along with 3 months average availability with less than 100% guaranteed connected to the org
     * @param reportDate Date formats: [dd/MM/yyyy][yyyy/MM/dd][dd-MM-yyyy][yyyy-MM-dd]
     * @param xAuthScopeId
     * @param organizationNumbers Please use tscId instead
     * @param serviceType Parameter used for filtering only in network availability export
     * @param tscId
     * @returns NetworkAvailabilityResponse OK
     * @throws ApiError
     */
    public static getNetworkAvailability1(
        reportDate: string,
        xAuthScopeId: any,
        organizationNumbers?: Array<string>,
        serviceType?: string,
        tscId?: string,
    ): CancelablePromise<NetworkAvailabilityResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/reports/network-availability',
            headers: {
                'X-Auth-Scope-Id': xAuthScopeId,
            },
            query: {
                'organizationNumbers': organizationNumbers,
                'serviceType': serviceType,
                'tscId': tscId,
                'reportDate': reportDate,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                405: `Method Not Allowed`,
                410: `Gone`,
                415: `Unsupported Media Type`,
                500: `Internal Server Error`,
            },
        });
    }

}