/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type OrderCaseCreateRequestReporter = {
    sysId: string;
    preferredContactMethod?: OrderCaseCreateRequestReporter.preferredContactMethod;
};

export namespace OrderCaseCreateRequestReporter {

    export enum preferredContactMethod {
        SMS = 'SMS',
        PHONE = 'PHONE',
        EMAIL = 'EMAIL',
        WEB_PORTAL = 'WEB_PORTAL',
        SMS_EMAIL = 'SMS_EMAIL',
    }


}
