/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DisturbanceRequest } from '../models/DisturbanceRequest';
import type { DisturbanceResult } from '../models/DisturbanceResult';
import type { DisturbanceServiceResult } from '../models/DisturbanceServiceResult';
import type { MajorIncidentResult } from '../models/MajorIncidentResult';
import type { RelatedCompaniesResult } from '../models/RelatedCompaniesResult';
import type { SerialCustomerID } from '../models/SerialCustomerID';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DisturbanceControllerService {

    /**
     * @param serialCustomerId
     * @param requestBody
     * @returns DisturbanceResult OK
     * @throws ApiError
     */
    public static getDisturbance(
        serialCustomerId: SerialCustomerID,
        requestBody: DisturbanceRequest,
    ): CancelablePromise<DisturbanceResult> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/disturbance/{serialCustomerId}',
            path: {
                'serialCustomerId': serialCustomerId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @param serialCustomerId
     * @param requestBody
     * @returns DisturbanceServiceResult OK
     * @throws ApiError
     */
    public static getDisturbanceServices(
        serialCustomerId: SerialCustomerID,
        requestBody: DisturbanceRequest,
    ): CancelablePromise<DisturbanceServiceResult> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/disturbance/services/{serialCustomerId}',
            path: {
                'serialCustomerId': serialCustomerId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @param serialCustomerId
     * @param requestBody
     * @returns RelatedCompaniesResult OK
     * @throws ApiError
     */
    public static getRelatedCompanies(
        serialCustomerId: SerialCustomerID,
        requestBody: DisturbanceRequest,
    ): CancelablePromise<RelatedCompaniesResult> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/disturbance/relatedcompanies/{serialCustomerId}',
            path: {
                'serialCustomerId': serialCustomerId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @param serialCustomerId
     * @param requestBody
     * @returns MajorIncidentResult OK
     * @throws ApiError
     */
    public static getMajorIncident(
        serialCustomerId: SerialCustomerID,
        requestBody: DisturbanceRequest,
    ): CancelablePromise<MajorIncidentResult> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/disturbance/majorincident/{serialCustomerId}',
            path: {
                'serialCustomerId': serialCustomerId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

}