/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TwoDaysHours } from '../models/TwoDaysHours';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ContactHoursControllerService {

    /**
     * Fetches working hours for today and tomorrow. Returned time is of Europe/Stockholm timezone
     * @param name
     * @returns TwoDaysHours OK
     * @throws ApiError
     */
    public static getWorkHoursOfTwoDays(
        name: string,
    ): CancelablePromise<TwoDaysHours> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/contact-centers/{name}/two-days-hours',
            path: {
                'name': name,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                405: `Method Not Allowed`,
                415: `Unsupported Media Type`,
                500: `Internal Server Error`,
            },
        });
    }

}